import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadCrumb } from '@nationwide/internet-servicing-angular-components';
import { PersonalConstants } from 'app/common/personalConstants';
import { ROUTING } from 'app/common/routeConstants';
import { ClaimsOidcAuthService } from 'app/efnol-modernization/claims-oidc-auth/claims-oidc-auth.service';
import { ClaimsHelperService } from 'app/shared/services/claims-helper.service';
import { EnvironmentpickerService } from 'app/shared/services/environmentpicker.service';
import { HttpHeaderService } from 'app/shared/services/httpheader.service';
import { SessionService } from 'app/shared/services/session.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'is-faq',
    templateUrl: './faq.component.html',
    standalone: false
})
export class FaqComponent implements OnInit {
    accessToken!: string;
    fromMFA: string;
    clientId: string = environment.appProps.appClientId;
    environment!: string;
    selectedCategory!: string;
    claimNumber = '';
    contactPublicId!: any;
    isThirdParty = false;

    breadCrumbs: BreadCrumb[];
    homeIconLink = environment.NATIONWIDE.REGISTERED_LOGO_URL;
    currentPage = 'Frequently asked questions';
    policyNumber;
    serverId: string;

    constructor(
        private route: ActivatedRoute,
        private environmentpickerService: EnvironmentpickerService,
        private claimsHelperService: ClaimsHelperService,
        private sessionService: SessionService,
        private authService: ClaimsOidcAuthService,
        private httpHeader: HttpHeaderService
    ) {}

    ngOnInit(): void {
        this.policyNumber = this.sessionService.getPolicyNumber();
        this.claimNumber = this.claimsHelperService.getClaimId();
        this.fromMFA = this.sessionService.getSessionItemDecrypted('fromMFA');
        this.contactPublicId = sessionStorage.getItem(PersonalConstants.MFA_LOGIN_COMPONENT.CONTACTID);
        this.isThirdParty = sessionStorage.getItem('user') === 'mfa-non-member';
        this.selectedCategory = this.route.snapshot.paramMap.get('category');
        this.environment = environment.CLAIMS_DEV ? this.environmentpickerService.getEnvironmentCookie() : 'prod';
        this.accessToken = this.httpHeader.getAccessToken();
        this.serverId = environment.CLAIMS_DEV ? this.environmentpickerService.getEnvironmentServerNumber() : '';
        this.setBreadCrumbs();
    }

    setBreadCrumbs(): void {
        if (this.fromMFA === 'true') {
            this.homeIconLink = ROUTING.AUTO.BASE_PATH + ROUTING.AUTO.CHILD_PATH_TRACKER;
            this.breadCrumbs = [
                {
                    name: 'Claim Tracker',
                    link: `${ROUTING.AUTO.BASE_PATH}/${ROUTING.AUTO.CHILD_PATH_TRACKER}`
                }
            ];
        } else {
            sessionStorage.setItem('claimType', 'Auto');
            this.breadCrumbs = [
                {
                    name: 'All accounts',
                    link: environment.CLAIMS_OLD_APP.ALL_ACCOUNTS + PersonalConstants.CLAIMS_OLD_URLS.ACCOUNT_SUMMARY_PAGE
                }, {
                    name: 'Auto claims',
                    link: environment.CLAIMS_NEW_APP.BASE_URL + PersonalConstants.CLAIMS_URLS.CLAIM_LIST_PAGE
                }, {
                    name: 'Claim Tracker',
                    link: `${ROUTING.AUTO.BASE_PATH}/${ROUTING.AUTO.CHILD_PATH_TRACKER}?policyNumber=${encodeURIComponent(this.policyNumber)}`
                }
            ];
        }
    }
}
